<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header">
        <div class="modal-title">
          <a-icon type="unordered-list" />批量导出协议内容
        </div>
      </div>
      <!-- <react-table
 :table-data="tableData"
 :total-items="totalItems"
 :actions-type="actionsType"
 :columns="columns"
 :hide-row-selection="false"
 :hide-pagination="false"
 :scroll="{ x: 0 }"
 :current-page="pageNumber"
 @emitSelectChange="selectChange"
 @emitOnPageChange="updatePageChange"
 @emitShowSizeChange="updateShowSizeChange"
 > -->
      <ele-table
        ref="rt"
        :columns="columns"
        :table-data="tableData"
        :total-items="totalItems"
        :current-page="pageNumber"
        :actions-type="actionsType"
        :is-full="false"
        :hide-row-selection="false"
        :hide-pagination="false"
        @emitRefreshData="getData"
        @emitSelectChange="selectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <!-- 按钮 -->
        <a-row :gutter="12" class="btn--container">
          <a-col class="gutter-row" :span="6">
            <a-input
              v-model="searchDto.filterText"
              :placeholder="l('请输入协议名称')"
            />
          </a-col>
          <a-col class="gutter-row" :span="4">
            <a-button type="primary" @click="search">
              {{ l("Search") }}
            </a-button>
            <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
          </a-col>
          <a-col class="gutter-row" :span="14" align="right">
            <a-button
              type="danger"
              style="margin: 0 10px"
              @click="exportContentToExcel()"
              :disabled="this.totalItems <= 0"
            >
              <a-icon type="cloud-download" />
              <span>{{ l("导出协议内容") }}</span>
            </a-button>
          </a-col>
        </a-row>
      </ele-table>
    </section>
  </a-spin>
</template>
<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import EmailNotification from "@/components/react-email";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import ImportForm from "./importform.vue";
import { fileDownloadService } from "@/shared/utils";
export default {
  name: "signperson-list",
  mixins: [AppComponentBase, ModalComponentBase],
  components: { EleTable },
  data() {
    return {
      filterText: "",
      spinning: false,
      // 表单
      formLayout: "horizontal",
      isDisabled: false,
      tableKey: "",
      commonService: null,
      personnelService: null,
      startToEndDate: [],
      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      signStatuList: ["未签署", "单方签署", "已经签署", "归档", "已通知"],
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      // 用户表格
      columns: [
        {
          title: this.l("协议名称"),
          dataIndex: "agreementTemplateName",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "agreementTemplateName" },
        },
        // {
        //   title: this.l("Actions"),
        //   dataIndex: "actions",
        //   fixed: "right",
        //   sorter: true,
        //   align: "center",
        //   scopedSlots: { customRender: "actions" },
        // },
      ],
      selectedRowKeys: [],
      selectedRows: [],
      searchDto: {
        agreementTemplateId: "",
        projectId: null,
        signStatus: "",
        filterText: "",
        sorting: "",
        MaxResultCount: 50,
        SkipCount: 0,
      },
      projectData: [
        {
          value: null,
          text: "请选择",
        },
      ],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted() {
    // console.log(this);
    if (this.params) {
      this.$nextTick(() => {});
    }
    this.getData();
    this.initActionsType();
  },
  methods: {
    search() {
      this.pageNumber = 1;
      this.searchDto.SkipCount = 0;
      this.getData();
    },
    async _handAutoSign(eleAgreementId) {
      try {
        this.spinning = true;
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/HandAutoSign",
          params: {
            id: eleAgreementId,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: "手动盖章完成",
        });
        this.spinning = false;
      } catch (err) {
        this.spinning = false;
      }
    },
    async getData() {
      this.spinning = true;
      this.$nextTick(() => {
        this.$refs.rt.clearData();
      });
      try {
        const params = {
          filterText: this.searchDto.filterText,
          sorting: this.searchDto.sorting,
          maxResultCount: this.searchDto.MaxResultCount,
          skipCount: this.searchDto.SkipCount,
        };
        // console.log(params);
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonAgreementTemplate/GetAgreementTemplateGroupPaged",
          params,
        });
        this.tableData = res.items.map((item) => {
          return {
            ...item,
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(
          res.totalCount / this.SearchDto.MaxResultCount
        );
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "templete-list",
        isShow: true,
        fns: {
          dropList: [],
        },
      };
      if (this.signstatus == 2) {
        obj.fns.dropList = [
          {
            granted: true,
            name: this.l("预览协议"),
            icon: "eye",
            fn: (data) => {
              console.log(data);
              if (data.viewUrl) {
                window.open(data.viewUrl);
              } else {
                _this.$message.info("暂无协议");
              }
            },
          },
          {
            granted: true,
            name: this.l("下载协议"),
            icon: "download",
            fn: (data) => {
              if (data.downloadUrl) {
                window.location.href = data.downloadUrl;
              } else {
                _this.$message.info("暂无协议");
              }
            },
          },
        ];
        obj.fns.dropList.push({
          granted: true,
          name: this.l("邮箱通知"),
          icon: "notification",
          fn: (data) => {
            _this.emailNotification(data, "edit");
          },
        });
      }
      if (this.signstatus == 1) {
        obj.fns.dropList = [
          // {
          // granted: true,
          // name: this.l("盖章"),
          // icon: "book",
          // fn: (data) => {
          // if (data.electronicAgreementId == null) {
          // this.$message.error("用户还未发起签署，无法盖章");
          // return;
          // }
          // abp.message.confirm("是否确定盖章？", "提示", (result) => {
          // if (result) {
          // _this._handAutoSign(data.electronicAgreementId);
          // }
          // });
          // },
          // },
          {
            granted: true,
            name: this.l("预览协议"),
            icon: "eye",
            fn: (data) => {
              console.log(data);
              if (data.viewUrl) {
                window.open(data.viewUrl);
              } else {
                _this.$message.info("暂无协议");
              }
            },
          },
          {
            granted: true,
            name: this.l("下载协议"),
            icon: "download",
            fn: (data) => {
              if (data.downloadUrl) {
                window.location.href = data.downloadUrl;
              } else {
                _this.$message.info("暂无协议");
              }
            },
          },
        ];
        obj.fns.dropList.push({
          granted: true,
          name: this.l("邮箱通知"),
          icon: "notification",
          fn: (data) => {
            _this.emailNotification(data, "edit");
          },
        });
      }
      if (this.signstatus == 0) {
        obj.fns.dropList = [
          {
            granted: true,
            name: this.l("通知签署"),
            icon: "notification",
            fn: (data) => {
              if (data.isNoty) {
                _this.$message.info("已通知过!");
                return;
              }
              let personnelIds = [];
              personnelIds.push(data.personnelId);
              _this.notificationPersonnelAgreement(personnelIds);
            },
          },
          {
            granted: true,
            name: this.l("撤回"),
            icon: "delete",
            fn: (data) => {
              console.log(data);
              let personnelIds = [];
              personnelIds.push(data.personnelId);
              _this.delPersonnelAgreement(personnelIds);
            },
          },
        ];
        if (this.formId) {
          obj.fns.dropList.push({
            granted: true,
            name: this.l("扩展信息"),
            icon: "profile",
            fn: (data) => {
              _this.createReactModify(data, "edit");
            },
          });
        }
      }
      if (this.signstatus == 3) {
        obj.fns.dropList = [
          {
            granted: true,
            name: this.l("盖章"),
            icon: "book",
            fn: (data) => {
              if (data.electronicAgreementId == null) {
                this.$message.error("用户还未发起签署，无法盖章");
                return;
              }
              abp.message.confirm("是否确定盖章？", "提示", (result) => {
                if (result) {
                  _this._handAutoSign(data.electronicAgreementId);
                }
              });
            },
          },
          {
            granted: true,
            name: this.l("预览协议"),
            icon: "eye",
            fn: (data) => {
              console.log(data);
              if (data.viewUrl) {
                window.open(data.viewUrl);
              } else {
                _this.$message.info("暂无协议");
              }
            },
          },
          {
            granted: true,
            name: this.l("下载协议"),
            icon: "download",
            fn: (data) => {
              if (data.downloadUrl) {
                window.location.href = data.downloadUrl;
              } else {
                _this.$message.info("暂无协议");
              }
            },
          },
        ];
        obj.fns.dropList.push({
          granted: true,
          name: this.l("邮箱通知"),
          icon: "notification",
          fn: (data) => {
            _this.emailNotification(data, "edit");
          },
        });
      }
      this.actionsType = obj;
    },
    async emailNotification(item, type) {
      ModalHelper.create(
        EmailNotification,
        {
          params: {
            id: item.id,
            realName: item.realName,
          },
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    /**
     * 导出为excel
     */
    async exportToRevocationExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/GetPageDataExcelFileForRevocation",
          params: {
            projectAgreementId: this.signPerson.id,
            signStatus: this.signstatus,
            filterText: this.searchDto.filterText,
            sorting: this.searchDto.sorting,
            startDate: (this.searchDto.StartDate =
              this.startToEndDate[0] === null
                ? undefined
                : this.startToEndDate[0]),
            endDate: (this.searchDto.EndDate =
              this.startToEndDate[1] === null
                ? undefined
                : this.startToEndDate[1]),
            maxResultCount: this.searchDto.MaxResultCount,
            skipCount: this.searchDto.SkipCount,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/GetPageDataExcelFile",
          params: {
            projectAgreementId: this.signPerson.id,
            signStatus: this.signstatus,
            filterText: this.searchDto.filterText,
            sorting: this.searchDto.sorting,
            startDate: (this.searchDto.StartDate =
              this.startToEndDate[0] === null
                ? undefined
                : this.startToEndDate[0]),
            endDate: (this.searchDto.EndDate =
              this.startToEndDate[1] === null
                ? undefined
                : this.startToEndDate[1]),
            maxResultCount: this.searchDto.MaxResultCount,
            skipCount: this.searchDto.SkipCount,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    /*
     * 导出合同内容
     */
    async exportContentToExcel() {
      console.log(this.selectedRowKeys);
            const selectCount = this.selectedRowKeys.length;
      if (selectCount !== 1) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "导出"));
        return;
      }
      
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/GetPageContractContentExcelByAgreementTemplate",
          params: {
            agreementTemplateId: this.selectedRowKeys[0],
            filterText: this.searchDto.filterText,
            sorting: this.searchDto.sorting,
            maxResultCount: this.searchDto.MaxResultCount,
            skipCount: this.searchDto.SkipCount,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    async createReactModify(item, type) {
      let list = [];
      const orginListLength = list.length;
      let reqParams = [];
      if (item && item.personnelId) {
        // console.log(item)
        reqParams.push({ key: "id", value: item.id });
        reqParams.push({ key: "personnelId", value: item.personnelId });
        reqParams.push({ key: "formId", value: this.formId });
      }
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams],
            reqUrl: {
              getById:
                "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/GetAgreementContentInfo",
              save: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/SaveAgreementContentInfo",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              res.formItems.map((_item, index) => {
                switch (_item.itemType) {
                  case "Text":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonInput",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      changeFn: () => {},
                    });
                    break;
                  case "Textarea":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "textarea",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        { max: _item.maxLength, message: this.l("MaxLength") },
                      ],
                      span: 24,
                      defaultValue: "",
                      changeFn: () => {},
                    });
                    break;
                  case "Number":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "numberInput",
                      disabled: false,
                      required: _item.isRequire,
                      max: _item.maxLength,
                      min: 0,
                      key: _item.key,
                      rules: [],
                      span: 24,
                      changeFn: () => {},
                    });
                    break;
                  case "Select":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "commonSelect",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Checkbox":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "checkbox",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Radio":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "radio",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [{ required: true, message: _item.remark }],
                      span: 24,
                      defaultSelectList:
                        _item.options === "[]" ? [] : JSON.parse(_item.options),
                      changeFn: () => {},
                    });
                    break;
                  case "Date":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "datePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      changeFn: () => {},
                    });
                    break;
                  case "Upload":
                    list.push({
                      label: _item.name,
                      value: [],
                      type: "upload",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [],
                      subdirName: environment.production ? "abpProd" : "abpDev",
                      uploadUrl: AppConsts.uploadBaseUrl + "/API/Upload",
                      limitedLength: _item.maxLength,
                      span: 24,
                      changeFn: (info) => {
                        let fileList = [...info.fileList];
                        fileList = fileList.map((file) => {
                          if (file.response) {
                            file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
                          }
                          return file;
                        });
                        list[_item.sort + orginListLength].value = fileList;
                      },
                    });
                    break;
                  case "DateRange":
                    list.push({
                      label: _item.name,
                      value: _item.key,
                      type: "rangeDatePicker",
                      disabled: false,
                      required: _item.isRequire,
                      key: _item.key,
                      rules: [
                        {
                          type: "array",
                          required: true,
                          message: _item.remark,
                        },
                      ],
                      span: 24,
                      changeFn: () => {},
                    });
                    break;
                  default:
                    throw new Error("暂无匹配项目");
                }
              });
              // console.info(item)
              // if (!item) {
              // form.setFieldsValue({
              // ProjectCode: await this._getProjectCode(),
              // });
              // } else {
              // form.setFieldsValue({
              // ProjectName: item.projectName,
              // ProjectType: item.projectType,
              // OrganizationUnitId: item.organizationUnitId,
              // ProjectTime: [item.beginDate, item.endDate],
              // CustomerName: item.customerName,
              // ProjectCode: item.projectCode,
              // });
              // }
              if (res.results && Object.keys(res.results).length !== 0) {
                const oResult = res.results;
                let opts = {};
                list.map((it) => {
                  if (it.key) {
                    let k = it.key;
                    let ores = oResult[k];
                    if (!ores) {
                      return;
                    }
                    if (it.type === "upload") {
                      if (oResult[k].value.indexOf(",") !== -1) {
                        let arr = oResult[k].value.split(",");
                        arr.map((val, i) => {
                          let n = val.split("/");
                          it.value.push({
                            uid: `-${i + 1}`,
                            url: `${AppConsts.uploadBaseUrl}${val}`,
                            name: n[n.length - 1],
                            response: { data: { Path: val } },
                            status: "done",
                          });
                        });
                      } else {
                        if (oResult[k].value && oResult[k].value.length) {
                          let n = oResult[k].value.split("/");
                          it.value.push({
                            uid: `-1`,
                            url: `${AppConsts.uploadBaseUrl}${res.pictureUrls}`,
                            name: n[n.length - 1],
                            response: { data: { Path: res.pictureUrls } },
                            status: "done",
                          });
                        }
                      }
                    } else if (it.type === "rangeDatePicker") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf("至") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split("至");
                    } else if (it.type === "datePicker") {
                      opts[`${k}`] = oResult[k].value
                        ? moment(oResult[k].value, "YYYY-MM-DD")
                        : null;
                    } else if (it.type === "checkbox") {
                      opts[`${k}`] =
                        oResult[k].value.indexOf(",") === -1
                          ? [oResult[k].value]
                          : oResult[k].value.split(",");
                    } else {
                      opts[`${k}`] = oResult[k].value;
                    }
                  }
                });
                // console.log(opts)
                this.$nextTick(() => {
                  form.setFieldsValue({ ...opts });
                });
              }
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let fileUrl = [];
              for (let i = 0; i < list.length; i++) {
                if (list[i].type === "upload" && list[i].required) {
                  if (!list[i].value.length) {
                    this.$message.info(`请上传${list[i].label}的附件资源`);
                    break;
                  }
                }
              }
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);
              let dynamicList = list.filter((item) => item.key);
              let _results = [];
              // console.log(dynamicList);
              dynamicList.map((_item) => {
                if (
                  _item.type === "commonInput" ||
                  _item.type === "numberInput" ||
                  _item.type === "textarea" ||
                  _item.type === "radio" ||
                  _item.type === "commonSelect"
                ) {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value: values[`${_item.value}`],
                    },
                  });
                } else if (_item.type === "datePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      // value: values[`${_item.value}`].format('YYYY-MM-DD')
                      value: values[`${_item.value}`],
                    },
                  });
                } else if (_item.type === "rangeDatePicker") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`][0] +
                        "至" +
                        values[`${_item.value}`][1],
                    },
                  });
                } else if (_item.type === "checkbox") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        values[`${_item.value}`].length === 1
                          ? values[`${_item.value}`][0]
                          : values[`${_item.value}`].join(","),
                    },
                  });
                } else if (_item.type === "upload") {
                  _results.push({
                    key: _item.key,
                    value: {
                      label: _item.label,
                      value:
                        _item.value.length === 1
                          ? `${_item.value[0].response.data.Path}`
                          : _item.value
                              .map((v) => {
                                return v.response.data.Path;
                              })
                              .join(","),
                    },
                  });
                }
              });
              let submitData = {
                personnelId: item && item.personnelId ? item.personnelId : null,
                formId: this.formId,
                results: _results,
              };
              // console.log('submitData:', submitData)
              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: { ...submitData },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.searchDto.filterText = "";
      this.pageNumber = 1;
      this.searchDto.SkipCount = 0;
      this.getData();
    },
    delPersonnelAgreement(personnelIds) {
      let _that = this;
      this.message.confirm("是否确定撤回", (res) => {
        if (res) {
          _that.delData(personnelIds);
        }
      });
    },
    batchDelData() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "撤回"));
        return;
      }
      console.log(this.selectedRowKeys);
      console.log(this.selectedRows);
      let personnelIds = this.selectedRows.map((item) => {
        return item.personnelId;
      });
      let projectAgreementId = this.selectedRows.map((item) => {
        return item.id;
      });
      let projectAgreementIdArr = projectAgreementId.filter(function (
        item,
        index,
        projectAgreementId
      ) {
        return projectAgreementId.indexOf(item, 0) === index;
      });
      let _that = this;
      _that.message.confirm(
        `是否对${personnelIds.length}个人进行撤回`,
        "撤回",
        (res) => {
          if (res) {
            _that.delData(personnelIds);
          }
        }
      );
    },
    async delData(personnelIds) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonAgreementTemplate/Revocation",
          params: {
            projectAgreementId: this.signPerson.id,
            personnelIds: personnelIds,
          },
        });
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: res,
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    batchDownload() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "下载"));
        return;
      }
    },
    notification() {
      console.log(this.selectedRowKeys);
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "通知签署"));
        return;
      }
      console.log(this.selectedRowKeys);
      console.log(this.selectedRows);
      let personnelIds = this.selectedRows.map((item) => {
        return item.personnelId;
      });
      let projectAgreementId = this.selectedRows.map((item) => {
        return item.id;
      });
      let projectAgreementIdArr = projectAgreementId.filter(function (
        item,
        index,
        projectAgreementId
      ) {
        return projectAgreementId.indexOf(item, 0) === index;
      });
      let _that = this;
      _that.message.confirm(
        `是否对${personnelIds.length}个人进行通知签署`,
        "系统提示",
        (res) => {
          if (res) {
            _that.notificationData(personnelIds);
          }
        }
      );
    },
    notificationPersonnelAgreement(personnelIds) {
      let _that = this;
      this.message.confirm("是否确定通知签署", (res) => {
        if (res) {
          _that.notificationData(personnelIds);
        }
      });
    },
    importData() {
      ModalHelper.create(
        ImportForm,
        { formId: this.formId },
        {
          width: "500px",
        }
      ).subscribe((res) => {
        this.refreshGoFirstPage();
      });
    },
    async notificationData(personnelIds) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonAgreementTemplate/SendSignNotice",
          params: {
            projectAgreementId: this.signPerson.id,
            personnelIds: personnelIds,
          },
        });
        this.refreshGoFirstPage();
        console.log(res);
        this.$notification["success"]({
          message: res,
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    selectChange(data) {
      console.log(data);
      let { selectedRowKeys, selectedRows } = data;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    handleChange(value) {
      this.searchDto.projectId = value;
      // console.log(value);
    },
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.searchDto.SkipCount = (page - 1) * this.searchDto.MaxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.searchDto.MaxResultCount = size;
      this.getData();
    },
  },
};
</script>
<style scoped lang="less">
// @import "../../../../styles/common.less";
.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
</style>