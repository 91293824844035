<template>
  <div>
    <div class="modal-header">
        <div class="modal-title">
            <span>邮箱通知</span>
        </div>
    </div>
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :form="form">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item :label="l('邮箱地址')">
            <a-input
              :placeholder="l('请输入')"
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div class="modal-footer">
      <a-button :disabled="saving" type="button" @click="close()">
        {{ l("Cancel") }}
      </a-button>
      <a-button :disabled="saving" type="primary" @click="handleSubmit">
        发送
      </a-button>
    </div>
  </div>
</template>
<script>

import { ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { Alert } from 'ant-design-vue';

export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-blogrolls",
  props: ["id", "type"],
  data() {
    return {
       commonService: null,
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
  },
  methods: {
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      this.saving = true;
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            let res = await this.commonService.post({
              url: "/api/services/app/UnnaturalPersonProjectPersonnelAgreement/EmailNotification",
              params: {
                Id: this.params.id,
                RealName: this.params.realName,
                Email: values.email,
              },
            });
            if (res) {
                  abp.message.success("发送成功！");
                  this.hideBatchApply();                
            } else {
                  abp.message.error("发送失败！");
            }
            
            this.saving = false;

          } catch {
            this.$emit("emitSpinning", "0");
            this.saving = false;
          }
        } else {
          this.saving = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
  display: block;
  // margin: 10px auto;
  text-align: right;
}

.modal-footer {
  padding: 10px 0;
  margin: 0;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
