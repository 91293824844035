<template>
  <a-spin :spinning="spinning">
    <!-- <page-header title="协议签署管理"></page-header> -->
    <!-- <a-card :bordered="false"> -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :is-full="true"
      :current-page="pageNumber"
      :scroll="{ x: 0 }"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table :columns="columns" :table-data="tableData" :total-items="totalItems" :is-full="true"
      :current-page="pageNumber" :hide-row-selection="true" @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange" @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange" @emitLinkClick="linkClick">
      <a slot="totalCount" slot-scope="text">{{ text }}</a>
      <a-row :gutter="8">
        <a-col :span="5">
          <a-input v-model="SearchDto.ProjectName" placeholder="请选择项目" @click="selectProjectModal">
          </a-input>
          <a-input v-model="SearchDto.ProjectId" type="hidden"> </a-input>
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-input v-model.trim="SearchDto.filterText" :placeholder="l('协议名称')" />
        </a-col>
        <!-- <a-col class="gutter-row" :span="5"> </a-col> -->
        <a-col class="gutter-row" :span="4">
          <a-button type="primary" @click="search" v-if="isGranted('Pages.UnnaturalPersonAgreementTemplate.Query')">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" :span="10" style="text-align: right">
          <a-button-group>
            <a-button v-if="
              isGranted(
                'Pages.UnnaturalPersonAgreementTemplate.ContractContentExcelByAgreementTemplate'
              )
            " @click="exportToExcel()">
              <a-icon type="file-excel" />
              <span>{{ "批量导出协议内容" }}</span>
            </a-button>
          </a-button-group>
        </a-col>
      </a-row>
    </ele-table>
    <!-- </a-card> -->
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import signperonlist from "./agreementsignpersonmanage/signperonlist";
import SelectPageList from "@/components/linggong/select-page-list";
import agreementtemplatelistform from "./agreementtemplatelistform.vue";
let vm = this;

export default {
  mixins: [AppComponentBase],
  name: "agreementsignmanage",
  components: { EleTable },

  data () {
    return {
      spinning: false,
      SearchDto: {
        SkipCount: 0,
        MaxResultCount: 50,
        Sorting: "",
        filterText: "",
        ProjectId: "",
        ProjectIds: "",
        ProjectName: "",
      },
      name: "",
      projectData: [
        // {
        //   value: "1",
        //   text: "测试项目1",
        // },
        // {
        //   value: "2",
        //   text: "测试项目2",
        // },
      ],
      selectedRowKeys: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      child: "",
      commonService: null,
      selectProjectList: "",
    };
  },
  created () {
    console.log(this);
    // this.initChild();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    // this.getProject();
    this.getData();
    this.initActionsType();
  },
  methods: {
    selectProjectModal () {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/UnnaturalPersonProject/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        console.log(res);
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }
          this.SearchDto.ProjectIds = projectIds; //data.id;
          this.SearchDto.ProjectName = projectNames; //data.name;
          this.pageNumber = 1;
          this.SearchDto.SkipCount = 0;
          this.getData();
          // this.getTaskByProjectId(0, data.id);
        }
      });
    },
    initColumns () {
      var vm = this;
      //   var child = vm.$createElement("a", {
      //     domProps: {
      //       innerHTML: 66,
      //     },
      //     on: {
      //       click: function () {
      //         vm.signClick();
      //       },
      //     },
      //   });
      this.columns = [
        {
          title: this.l("所属项目"),
          dataIndex: "projectName",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: this.l("协议名称"),
          dataIndex: "agreementTemplateName",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "agreementTemplateName" },
        },
        {
          title: this.l("状态"),
          dataIndex: "status",
          sorter: true,
          align: "center",
          type: "tag",
          scopedSlots: { customRender: "status" },
          customRender: function (text, record, index) {
            if (text == "Enabled") {
              return "启用";
            } else {
              return "禁用";
            }
          },
        },
        {
          title: this.l("发送签署总人数/个"),
          dataIndex: "totalPersonCount",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "totalPersonCount" },
        },
        {
          title: this.l("已归档"),
          dataIndex: "archiveCount",
          // sorter: true,
          align: "center",
          type: "link",
          customRender: function (text, record, index) {
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: text + " (查看)",
              },
              on: {
                click: function () {
                  vm.archiveClick(record);
                },
              },
            });
            var obj = {
              //   children: <a href="javascript:;">{text + " (查看)"}</a>,
              children: child,
              attrs: {},
              on: {
                click: () => {
                  this.$message.info(text);
                },
              },
            };
            return obj;
          },
          scopedSlots: { customRender: "archiveCount" },
        },
        {
          title: this.l("已签署"),
          dataIndex: "signedCount",
          // sorter: true,
          align: "center",
          type: "link",
          customRender: function (text, record, index) {
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: text + " (查看)",
              },
              on: {
                click: function () {
                  vm.signClick(record);
                },
              },
            });
            var obj = {
              //   children: <a href="javascript:;">{text + " (查看)"}</a>,
              children: child,
              attrs: {},
              on: {
                click: () => {
                  this.$message.info(text);
                },
              },
            };
            return obj;
          },
          scopedSlots: { customRender: "signedCount" },
        },
        {
          title: this.l("待盖章"),
          dataIndex: "sealedCount",
          // sorter: true,
          align: "center",
          type: "link",
          customRender: function (text, record, index) {
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: text + " (查看)",
              },
              on: {
                click: function () {
                  vm.sealedClick(record);
                },
              },
            });
            var obj = {
              children: child,
              attrs: {},
              on: {
                click: () => {
                  this.$message.info(text);
                },
              },
            };
            return obj;
          },
          scopedSlots: { customRender: "sealedCount" },
        },
        {
          title: this.l("未签署"),
          dataIndex: "nonSignCount",
          // sorter: true,
          align: "center",
          type: "link",
          customRender: function (text, record, index) {
            console.log(record);
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: text + " (查看)",
              },
              on: {
                click: function () {
                  vm.noSignClick(record);
                },
              },
            });
            var obj = {
              //   children: <a href="https://www.baidu.com/">{text + " (查看)"}</a>,
              children: child,
              attrs: {},
              on: {
                click: () => {
                  this.$message.info(text);
                },
              },
            };
            return obj;
          },
          scopedSlots: { customRender: "nonSignCount" },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ];
    },
    initChild (text) {
      var child = this.$createElement("a", {
        domProps: {
          innerHTML: text,
        },
        on: {
          click: function () {
            this.signClick(text);
          },
        },
      });
      this.child = child;
      console.log(child);
    },
    search () {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    getData () {
      this.getPaged();
      // this.tableData = [
      //   {
      //     name: "灵活经营服务协议",
      //     totalCount: 120,
      //     sign: 100,
      //     nosign: 20,
      //   },
      //   {
      //     name: "自由者从业协议",
      //     totalCount: 120,
      //     sign: 100,
      //     nosign: 20,
      //   },
      // ];
    },
    async getPaged () {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonAgreementTemplate/GetPaged",
          params: {
            projectId: this.SearchDto.ProjectId,
            projectIds: this.SearchDto.ProjectIds,
            filterText: this.SearchDto.filterText,
            sorting: this.SearchDto.Sorting,
            maxResultCount: this.SearchDto.MaxResultCount,
            skipCount: this.SearchDto.SkipCount,
          },
        });
        // console.log(res);
        this.tableData = res.items.map((item) => {
          return {
            ...item,
            signedCount: item.signedCount + " (查看)",
            nonSignCount: item.nonSignCount + " (查看)",
            archiveCount: item.archiveCount + " (查看)",
            sealedCount: item.sealedCount + " (查看)",
            status: item.status === "Enabled" ? "启用" : "禁用",
            statusColor: item.status === "Enabled" ? "#87d068" : "#f50",
          };
        });
        this.totalItems = res.totalCount;
        this.totalPages = Math.ceil(2 / this.SearchDto.MaxResultCount);
        this.pagerange = [
          (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
          this.pageNumber * this.SearchDto.MaxResultCount,
        ];
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    initActionsType () {
      let _this = this;
      let obj = {
        type: "sign-manage",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted(
                "Pages.UnnaturalPersonAgreementTemplate.PreviewAgreement"
              ),
              name: this.l("预览协议"),
              icon: "ant-design",
              fn: (data) => {
                _this.lookSign(data);
              },
            },
            // {
            //   granted: true,
            //   name: this.l("查看已签署人员"),
            //   icon: "ant-design",
            //   fn: (data) => {
            //     _this.lookSignPerson(data);
            //   },
            // },
            // {
            //   granted: true,
            //   name: this.l("查看未签署人员"),
            //   icon: "ant-design",
            //   fn: (data) => {
            //     _this.lookSignPerson(data);
            //   },
            // },
          ],
        },
      };
      this.actionsType = obj;
    },
    // 排序更新data
    updateSortData (newV) {
      let { columnKey, order } = newV;
      this.SearchDto.Sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange (newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.SearchDto.MaxResultCount = pageSize;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.getData();
    },
    updateShowSizeChange (newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange (newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    linkClick (newV) {
      const { item, index } = newV;
      if (index === 4) {
        this.archiveClick(item);
      }
      else if (index === 5) {
        this.signClick(item);
      }
      else if (index === 6) {
        this.sealedClick(item);
      } else if (index === 7) {
        this.noSignClick(item);
      }
    },
    lookSign (item) {
      const { pdfTemplateUrl } = item;
      if (pdfTemplateUrl) {
        window.open(`${AppConsts.uploadBaseUrl}${pdfTemplateUrl}`);
      } else {
        this.$message.info("暂无协议");
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh () {
      this.refreshGoFirstPage();
    },
    refreshGoFirstPage () {
      this.SearchDto.filterText = "";
      this.SearchDto.ProjectIds = "";
      this.SearchDto.ProjectName = "";
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // 分页事件
    showTotalFun () {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    archiveClick (item) {
      //已归档
      //   this.message.success(text);
      this.lookSignPerson(item, 2);
    },
    signClick (item) {
      //已签署
      //   this.message.success(text);
      this.lookSignPerson(item, 1);
    },
    noSignClick (item) {
      //未签署
      //   this.message.success("测试");
      this.lookSignPerson(item, 0);
    },
    sealedClick (item) {
      //待盖章
      this.lookSignPerson(item, 3);
    },
    lookSignPerson (item, signstatus) {
      ModalHelper.create(
        signperonlist,
        {
          signPerson: item,
          signstatus: signstatus,
          formId: item.formId,
        },
        {
          isChange: true,
          width: "1300px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    exportToExcel () {
      ModalHelper.create(
        agreementtemplatelistform,
        {
          signPerson: {},
          signstatus: 2,
          formId: "",
        },
        {
          isChange: true,
          width: "1000px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
  },
};
</script>

<style scoped lang="less"></style>
